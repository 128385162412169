<template>
  <b-card-code>
    <!-- BODY -->
    <validation-observer
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="onSubmit"
      >
        <b-row>
          <b-col>
            <i2-form-vue-select
              v-model="model.company"
              name="company"
              rules="required"
              label="Empresa"
              :options="companiesList"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="6"
            sm="12"
          >
            <i2-form-vue-select
              v-model="model.unity"
              name="unity"
              rules="required"
              label="Unidade"
              :options="unitiesList"
            />
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <i2-form-vue-select
              v-model="model.well"
              name="well"
              rules="required"
              label="Poço"
              :options="wellList"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="6"
            sm="12"
          >
            <i2-form-input-file
              v-model="model.file"
              name="file"
              rules="required"
              label="Arquivo de leituras"
              accept=".csv"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            sm="12"
            md="12"
            class="text-right"
          >
            <!-- Form Actions -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
            >

              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              Processar arquivo
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import I2FormVueSelect from '@/components/i2/forms/elements/I2FormVueSelect.vue'
import { mapActions } from 'vuex'
import I2FormInputFile from '@/components/i2/forms/elements/I2FormInputFile.vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'InputDataFile',
  components: {
    ValidationObserver,
    I2FormVueSelect,
    I2FormInputFile,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      companiesList: [],
      unitiesList: [],
      wellList: [],
      model: {
        company: {
          value: '',
          text: '',
        },
        unity: {
          value: '',
          text: '',
        },
        well: {
          value: '',
          text: '',
        },
        file: [],
      },
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'model.company': async function (data) {
      if (this.model.company.value !== '') {
        const unities = await this.getUnities(data.value)
        if (unities.length > 0) {
          this.unitiesList = unities.map(item => ({
            value: item.uuid,
            text: item.name,
            wells: item.wells,
          }))
        }
      }
    },
    // eslint-disable-next-line func-names
    'model.unity': async function () {
      if (this.model.unity.value !== '') {
        this.wellList = this.model.unity.wells.map(item => ({
          value: item.uuid,
          text: item.name,
        }))
      }
    },
  },
  async created() {
    const companies = await this.getCompanies()
    this.companiesList = companies.map(item => ({
      value: item.uuid,
      text: item.name,
    }))
  },
  methods: {
    ...mapActions('company', ['getCompanies', 'getUnities']),
    onSubmit() {
      this.$refs.refFormObserver.validate()
        .then(async success => {
          if (success) {
            const formData = new FormData()
            formData.append('company', this.model.company.value)
            formData.append('unity', this.model.unity.value)
            formData.append('well', this.model.well.value)
            formData.append('file', this.model.file)

            const response = await this.$http.post('/readings/file', formData)
            if (!response.error) {
              this.$notify.success('Arquivo foi processado com sucesso.')
              this.onReset()
              return
            }
            this.$notify.error('Aconteceu um erro: '.concat(response.error_message))
          }
        })
    },
    onReset() {
      this.model = {
        company: {
          value: '',
          text: '',
        },
        unity: {
          value: '',
          text: '',
          wells: [],
        },
        well: {
          value: '',
          text: '',
        },
        file: null,
      }
      this.$refs.refFormObserver.reset()
    },
  },
}
</script>

<style scoped>

</style>
